@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;

/*@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;*/

// @include foundation-progress-element;
// @include foundation-meter-element;

/*@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;*/

// @include foundation-flex-classes;

/*@include motion-ui-transitions;
@include motion-ui-animations;*/

//Font-Family
$serif: "游明朝",
"ヒラギノ明朝 Pro W3",
"Hiragino Mincho Pro",
"ＭＳ Ｐ明朝",
"MS PMincho",
serif;
$sans_serif: "ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic Pro",
"メイリオ",
YuGothic,
"Yu Gothic",
"游ゴシック",
Meiryo,
"ＭＳ Ｐゴシック",
"MS PGothic",
Osaka,
Helvetica,
Arial,
sans-serif;

$main_color:#4072b3;
$strong_color:#da6242;



























































/* mixin */

//Mixins
@mixin reset {
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  address,
  img,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
  }

  ol,
  ul {
    list-style: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  caption,
  th,
  td {
    text-align: left;
    font-weight: normal;
    vertical-align: middle;
  }

  q,
  blockquote {
    quotes: none;
  }
  q:before,
  q:after,
  blockquote:before,
  blockquote:after {
    content: "";
    content: none;
  }

  a img {
    border: none;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@function get_vw($size, $viewport:320) {
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@mixin fz_vw($font_size:10) {
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}

@mixin number_font {
  font-family: 'Roboto', sans-serif;
}

@mixin cursor($color:$main_color, $size1:5px, $size2:5px, $size3:5px) {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: $size1 0 $size2 $size3;
  border-color: transparent transparent transparent $color;
}

@mixin cursor2($size:6px, $color:#aec4e5, $rotate:45deg) {
  content: "";
  display: inline-block;
  border-top: 1px solid $color;
  border-right: 1px solid $color;
  width: $size;
  height: $size;
  -webkit-transform: rotate($rotate);
  transform: rotate($rotate);
}

@mixin position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

@mixin table-layout($padding01:14px, $padding02:18px) {
  tr:first-child {
    th,
    td {
      border-top: 0;
    }
  }
  tr {
    th,
    td {
      &:first-child {
        border-left: 0;
      }
    }
  }
  th,
  td {
    padding: $padding01 $padding02;
  }
  th {
    background: #aec4e5;
    font-weight: bold;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
  }
  td {
    background: #fff;
    font-weight: bold;
    border-top: 1px solid #d0d0d0;
    border-left: 1px solid #d0d0d0;
  }
}

@mixin text-align-right {
  text-align: right;
}

@mixin text-align-left {
  text-align: left;
}

.nav-is-open {
  @include breakpoint(medium down) {
        position: fixed;
  }
}


.ccm-page {
  /* reset */
  @include breakpoint(large only) {
    font-size: 0.87rem;
  }
  @include breakpoint(medium only) {
    font-size: 0.84rem;
  }
  font-size: 0.8125rem;
  @include breakpoint(small only) {
    font-size: 14px;
  }
  @include breakpoint(xlarge) {
    font-size: 0.9375rem;
  }
  @include breakpoint(xlarge down) {
    background: #f5f5f5;
  }
  font-family: $sans_serif;
  color:#333;
  @include reset;
  a {
    color: #333;
    text-decoration: none;
    cursor: pointer;
    transition: .4s;
    &:hover {
      text-decoration: underline;
      opacity: .7;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    }
  }
.wysiwyg{
  ul{
        list-style-type: disc;
        margin-left: 1.25rem;
  }
  ol{
        list-style-type: decimal;
        margin-left: 1.25rem;
  }
  h1{
        font-size: 1.5rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  h2{
    font-size: 1.25rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;  }
  h3{
    font-size: 1.1875rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;  }
  h4{
    font-size: 1.125rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;  }
  h5{
    font-size: 1.0625rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;  }
  h6{
    font-size: 1rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;  }
}
  img {
    vertical-align: bottom;
    width: auto;
    height: auto;
    max-width: 100%;
    &.img-change {
      @include breakpoint(large down) {
        width: 100%;
      }
    }
  }
  /* */
  .inner {
    @include breakpoint(large down) {
      width: 95%;
    }
    @include breakpoint(xlarge) {
      width: 92%;
    }
    margin: 0 auto;
  }
  .strong {
    color: $strong_color;
  }
  .mini {
    font-size: 80% !important;
  }

  .bold {
    font-weight: bold;
  }
  .medium {
    font-size: 120%;
  }
  .sup {
    font-size: 75.5%;
    vertical-align: top;
    position: relative;
    top: -0.1em;
  }
  .category {
    background: $strong_color;
    color: #fff;
    @include breakpoint(large down) {
      border-radius: 7px;
      padding: 2px 5px;
      font-size: 0.6875rem;
      margin-right: 5px;
    }
    @include breakpoint(xlarge) {
      border-radius: 10px;
      padding: 5px 9px;
      font-size: 18px;
      margin-right: 10px;
      top: -4px;
    }
    position: relative;
    top: -2px;
    font-weight: normal;
  }
  .number {
    @include number_font;
  }

  .text-align-right {
    @include text-align-right;
  }

  .text-align-left {
    @include text-align-left;
  }

  .hide-sp {
    @include breakpoint(xlarge down) {
      display: none;
    }
  }
  .show-large-xlarge {
    display: none;
    @include breakpoint(large only) {
      display: block;
    }
    @include breakpoint(xlarge only) {
      display: block;
    }
    @media screen and (min-width: 1200px) and (max-width: 1515px) {
      display: block;
    }
  }

  .l-left-column {
    background: #fff;
    @include breakpoint(xxlarge) {
      position: fixed;
      height: 100%;
      overflow-y: scroll;
      padding-bottom: 100px;
    }
  }

  .l-right-column {
    @include breakpoint(xlarge) {
      background: #f5f5f5;
    }
    .inner {
      @include breakpoint(xlarge) {
        width: 82%;
      }
    }
  }

  .l-header {
    background: #fff;
    @include breakpoint(xlarge) {
      padding-bottom: 15px;
    }
    @include breakpoint(large down) {
      padding-bottom: 7px;
    }
    .header-wrap {
      position: relative;
      @include breakpoint(xlarge only) {
        margin-bottom: 10px;
      }
    }
    .header-top {
      padding-top: 10px;
      @include breakpoint(large only) {
        padding-right: 10px;
      }
      @include breakpoint(xlarge) {
        padding-top: 21px;
      }
      @include breakpoint(medium down) {
        box-shadow: 0px 0px 6px 3px #ebebeb;
        -moz-box-shadow: 0px 0px 6px 3px #ebebeb;
        -webkit-box-shadow: 0px 0px 6px 3px #ebebeb;
      }
    }
    .inner {
      @include breakpoint(xlarge) {
        width: 95%;
      }
    }
    .logo {
      margin-bottom: 15px;
      padding-left: 3%;
      @include breakpoint(xxlarge) {
        padding-right: 4%;
      }
      @include breakpoint(xlarge) {
        padding-right: 5%;
      }
      @include breakpoint(medium down) {
        width: 45.9%;
      }
      @include breakpoint(small down) {
        width: 61.9%;
      }
      @include breakpoint(large only) {
        width: 100%;
        position: relative;
      }
      @media (max-width: 750px) and (orientation: landscape) {
        margin-bottom: 5px;
      }
    }
  }

  /* ハンバーガー
------------------------------------------------ */
  .hamburger,
  .hamburger span {
    display: inline-block;
    transition: all .4s;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: transparent;
  }

  .hamburger {
    position: relative;
    font-size: 14px;
    width: 32px;
    height: 26px;
    top: 12px;
    bottom: 0;
    left: 0;
    right: 5px;
    margin: auto;
    display: block;
    span {
      position: absolute;
      width: 100%;
      height: 4px;
      background-color: #4072b3;
      &:nth-of-type(1) {
        top: 0px;
      }
      &:nth-of-type(2) {
        top: 9px;
      }
      &:nth-of-type(3) {
        bottom: 4px;
      }
    }
  }

  .l-hamburger-wrap {
    @include breakpoint(large) {
      display: none;
    }
    position: fixed;
    right: 2%;
    @include breakpoint(large) {
      right: 1%;
    }
    z-index: 19;
    top: 7px;
    padding: 3px;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 1;
    }
    width: 50px;
    height: 50px;
    &.is-active {
      .hamburger {
        width: 35px;
      }
      span {
        &:nth-of-type(1) {
          top: -9px;
          -webkit-transform: translateY(17px) rotate(-45deg);
          transform: translateY(17px) rotate(-45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          bottom: 5px;
          -webkit-transform: translateY(-9px) rotate(45deg);
          transform: translateY(-9px) rotate(45deg);
        }
      }
    }
  }

  .hamburger-close-btn {
    position: absolute;
    right: 3%;
    z-index: 9999;
    padding: 3px;
    top: 26px;
    .hamburger {
      width: 35px;
      height: 31px;
      background: none;
      top: 0;
      left: 0;
      &:hover {
        background: none;
      }
    }
    span {
      background-color: #fff;
      height: 2px;
      left: 0;
      &:nth-of-type(1) {
        top: -9px;
        -webkit-transform: translateY(17px) rotate(-45deg);
        transform: translateY(17px) rotate(-45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        bottom: 13px;
        -webkit-transform: translateY(-9px) rotate(45deg);
        transform: translateY(-9px) rotate(45deg);
      }
    }
  }

  .l-gnavi {
    background: #fff;
    @include breakpoint(xlarge only) {}
    @include breakpoint(medium down) {
      font-size: 1rem;
      margin-bottom: 20px;
      font-size: 1.25rem;
      display: none;
      position: fixed;
      width: 100%;
      height: 100%;
      background: #fff;
      z-index: 900;
    }
    @include breakpoint(xxlarge only) {
      padding-left: 5%;
      padding-right: 5%;
    }
    @include breakpoint(xxlarge) {
      margin-bottom: 20px;
    }
    .gnavi-wrap {
      @include breakpoint(medium down) {
        @media screen and (max-height: 900px) {
          height:auto;
        }
        @media screen and (max-height:600px) {
          overflow-y: scroll;
        }
        @media screen and (max-height: 500px) {
          overflow-y: scroll;
        }
        @media screen and (max-height: 400px) {
          overflow-y: scroll;
        }
        @media screen and (max-height: 370px) {
          overflow-y: scroll;
        }
        @include breakpoint(xlarge) {
          height: auto;
        }
      }
    }
    ul {
      @media screen and (min-width: 961px) and (max-width: 1201px) {
        width: 100%;
      }
      @include breakpoint(medium down) {
        &:first-child+ul {
          li:first-child {
            border-top: 1px solid #d0d0d0;
          }
        }
        li:last-child {
          border-bottom: 0;
        }
        &:last-child {
          border-bottom: 1px solid #d0d0d0;
        }
      }
    }
    li {
      @include breakpoint(medium down) {
        border-bottom: 1px solid #d0d0d0;
      }
      @include breakpoint(large) {
        font-size: 0.85rem;
      }
      @include breakpoint(xxlarge only) {
        font-size: 0.9rem;
      }
      @include breakpoint(xxxlarge) {
        font-size: 1rem;
      }
      @media screen and (min-width: 961px) and (max-width: 1200px) {
        float: left;
        width: 16.65%;
        &:last-child:not(:first-child) {
          float: left;
        }
      }
      text-align:center;
      line-height:1em;
    }
    a {
      @include breakpoint(medium down) {
        padding: 20px 0;
      }
      @include breakpoint(large) {
        padding: 13px 0;
      }
      font-weight: bold;
      display: block;
    }
  }
  &.page-type-home {
    .l-group-information.hide-sp {
      display: block !important;
    }
  }
  .l-group-information {
    @include breakpoint(xxlarge) {
      margin-bottom: 16px;
    }
    @include breakpoint(xlarge down) {
      padding-right: 3%;
      .inner {
        width: auto;
      }
    }
    &.hide-sp {
      @include breakpoint(large) {
        display: block;
      }
    }
    .title {
      @include breakpoint(xxlarge) {
        text-align: center;
        margin-bottom: 25px;
        font-size: 0.875rem;
      }
      @include breakpoint(xlarge down) {
        width: 33%;
        display: inline-block;
        padding-left: 3%;
        top: -9px;
        position: relative;
        background: #4072b3;
        padding: 15px 5px;
        color: #fff;
        border-radius: 0 0 6px 0;
      }
      @include breakpoint(large down) {
        top: -2vw;
        padding: 10px 5px;
      }
      @include breakpoint(large only) {
        top: -0.8vw;
        padding: 19px 5px;
      }
      @include breakpoint(medium only) {
        top: -0.8vw;
        padding: 19px 5px;
      }
      @include breakpoint(small only) {
        top: -0.4vw;
      }
      @media screen and(max-width:461px) {
        top: 0;
      }
      @media screen and (min-width:370px) and (max-width: 600px) {
        padding: 10px 13px;
      }
      span {
        @include breakpoint(xxlarge) {
          font-size: 1.1875rem;
          color: $main_color;
          line-height: 1.25em;
        }
        @include breakpoint(xlarge down) {
          font-size: 0.8125rem;
        }
        display: block;
        font-weight: bold;
      }
      @include breakpoint(xlarge down) {
        font-size: 0.53125rem;
      }
    }
    .group-information-list {
      @include breakpoint(xxlarge) {
        width: 84%;
        margin: 0 auto;
      }

      @include breakpoint(xlarge down) {
        display: inline;
      }
    }
    dl {
      border-bottom: 3px solid #f5f5f5;
      @include breakpoint(xlarge down) {
        &:first-child,
        &:first-child+dl {
          padding-top: 10px;
        }
      }
      @include breakpoint(xxlarge) {
        display: table;
        width: 100%;
        margin-top: 20px;
        line-height: 2.25em;
        &:first-child {
          margin-top: 0;
        }
      }
      @include breakpoint(medium down) {
         :first-child,
        &:first-child+dl {
          padding-top: 0;
        }
      }
      @include breakpoint(xlarge down) {
        display: inline-block;
        width: 30%;
        margin-left: 2%;
        margin-top: 10px;
        &:first-child,
        &:first-child+dl {
          margin-top: 0;
        }
        &:nth-child(3) {
          margin-left: 0;
          margin-left: 3%;
        }
      }
    }
    dt {
      font-size: 0.75rem;
      line-height: 1em;
      @include breakpoint(xxxlarge) {
        padding-left: 5%;
      }
      @include breakpoint(xxlarge) {
        display: table-cell;
        vertical-align: middle;
        line-height: 1.35em;
      }
      @include breakpoint(xxlarge only) {
        padding-left: 1%;
        padding-right: 1%;
      }
      @media screen and (min-width: 1570px) {
        padding-left: 13px;
      }
    }
    dd {
      text-align: right;
      font-size: 1.125rem;
      font-weight: bold;
      @include breakpoint(xxlarge) {
        display: table-cell;
        padding-right: 2%;
      }
      @include breakpoint(xxxlarge) {
        display: table-cell;
      }
      @media screen and (min-width: 1570px) {
        padding-right: 13px;
      }
      @include breakpoint(xlarge down) {
        line-height: 2.25em;
      }
      @include breakpoint(small only) {
        line-height: normal;
      }
      span {
        @include number_font;
        padding-right: 5px;
        @include breakpoint(xxlarge) {
          font-size: 43px;
        }
        @media screen and (min-width:1200px) and (max-width: 1811px) {
          font-size: 1.8vw;
        }
        @include breakpoint(xlarge only) {
          font-size: 2.25rem;
        }
        @include breakpoint(large only) {
          font-size: 2.25rem;
        }
        @include breakpoint(medium only) {
          font-size: 2.25rem;
        }
        @include breakpoint(small only) {
          font-size: 1.3rem;
        }
        @media screen and (min-width:370px) and (max-width: 600px) {
          font-size: 25px;
        }
        @include number_font;
        color: #da6242;
        font-weight: bold;
      }
    }
  }
  .l-mainvisual.top {
    width: 100%;
    @include breakpoint(xlarge) {
    }
    @include breakpoint(large down) {
    }
    //background: url(../img/mainvisual.png) center no-repeat;
    //background-size: cover;
    img{
      width:100%;
      height:100%;
    }
    position: relative;
  }
  .mainvisual-txt {
    img{
      width:auto !important;
      height:auto !important;
    }
    @include breakpoint(xxlarge) {
      width: 75%;
      margin: 0 auto;
    }
    @include breakpoint(xlarge down) {
      width: 94%;
      margin: 0 auto;
    }
    @include breakpoint(xxlarge) {
      font-size: 40px;
    }
    @include breakpoint(xlarge only) {
      width: 77%;
      font-size: 3vw;
      top: 34px;
    }
    @include breakpoint(large only) {
      width: 77%;
      font-size: 3vw;
      top: 34px;
    }
    @include breakpoint(medium only) {
      width: 77%;
      font-size: 3.5vw;
      top: 34px;
    }
    @include breakpoint(xxlarge) {
      top: 90px;
    }
    @include breakpoint(xlarge down) {
      top: 40px;
    }
    @include breakpoint(small only) {
      font-size: 4.75vw;
      top: 34px;
    }
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
  .l-topics-area {
    a {
      display: block;
      position: relative;
      z-index: 3;
    }
    @include breakpoint(medium down) {
      padding-top: 15px;
    }
    .inner {
      @include breakpoint(large) {
        width: 100%;
        background: #fff;
      }
    }
    .topics-banner-wrap {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f5cca0+0,f6eac7+100 */
      background: rgb(245, 204, 160);
      /* Old browsers */
      background: -moz-linear-gradient(left, #aec4e5 0%, #ffc27a 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left, #aec4e5 0%, #ffc27a 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, #aec4e5 0%, #ffc27a 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#aec4e5', endColorstr='#ffc27a', GradientType=1);
      /* IE6-9 */
      position: relative;
      z-index: 10;
      overflow: hidden;
      @include breakpoint(medium down) {
        margin-top: 5px;
      }
    }
      .topicbanner-photo{
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height:100%;
        @include breakpoint(xlarge){
          height:83%;
        }
      }
    dl {
      @include breakpoint(large only) {
        /*        padding: 3vw 15px;
*/
        /*padding-right: 31.7%;*/
      }
      @include breakpoint(xlarge only) {
        /*        padding: 2.4vw 33% 2.6vw 40px;
*/
      }
      @include breakpoint(xxlarge) {
        /*        padding: 3vw 15px 3vw 39px;
*/
        /*padding-right: 31.7%;*/
      }
      @include breakpoint(medium only) {
        /*        padding: 3vw 15px;
*/
        /*padding-right: 31.7%;*/
      }
      @media screen and (min-width: 1473px) and (max-width: 1627px) {}
      @media screen and (min-width: 1201px) and (max-width: 1400px) {
        /*        padding: 2.5vw 15px;
*/
        /*padding-right: 33.7%;*/
      }
      @include breakpoint(small only) {
        /*        padding: 3vw 15px;
*/
        /* padding-right: 35.7%;*/
      }
      .columns {
        &:first-child {
          dl {
            margin-top: 0;
          }
        }
      }
      &:after {
        content: "";
        background: url(../img/bg_01.png) center;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
      }
      &.topics-banner-1 {
        &:before {
          content: "";
          background: url(../img/img_topics_01.png) right bottom no-repeat;
          @include breakpoint(medium only) {
            width: 29%;
          }
          @media screen and (min-width: 960px) and (max-width: 1400px) {
            background-image: url(../img/img_topics_01_1200.png);
            width: 37.7%;
          }
          @include breakpoint(xxlarge down) {
            background-size: 100%;
          }
          @include breakpoint(xxxlarge) {
            width: 286px;
            height: 191px;
          }
        }
      }
      &.topics-banner-2 {
        &:before {
          content: "";
          background: url(../img/img_topics_02.png) right bottom no-repeat;
          @media screen and (min-width: 960px) and (max-width: 1400px) {
            background-image: url(../img/img_topics_02_1200.png);
          }
          @include breakpoint(xxlarge down) {
            background-size: 100%;
          }
          @include breakpoint(xxxlarge) {
            width: 243px;
            height: 189px;
          }
          @media screen and (min-width: 1201px) and (max-width: 1400px) {
            width: 163px;
          }
        }
      }
    }
    dt {
      font-size: 2rem;
      line-height: 1em;
      /*margin-bottom: 23px;*/
      @include breakpoint(xxlarge only) {
        font-size: 1.6rem;
      }
      @include breakpoint(xlarge only) {
        font-size: 1.6rem;
      }
      @include breakpoint(large only) {
        font-size: 3vw;
      }
      @include breakpoint(medium only) {
        font-size: 1.3rem;
      }
      @include breakpoint(medium down) {
        margin-bottom: 10px;
      }
      @include breakpoint(small down) {
        font-size: 1.0375rem;
      }
      a {
        padding: 3vw 15px 23px 39px;
        @include breakpoint(large only) {
          padding: 3vw 15px 0 15px;
          padding-right: 31.7%;
        }
        @include breakpoint(xlarge only) {
          padding: 2.4vw 33% 23px 40px;
        }
        @include breakpoint(xxlarge) {
          padding: 3vw 15px 23px 39px;
          padding-right: 31.7%;
        }
        @include breakpoint(medium only) {
          padding: 3vw 15px 10px 15px;
          padding-right: 31.7%;
        }
        @media screen and (min-width: 1473px) and (max-width: 1627px) {}
        @media screen and (min-width: 1201px) and (max-width: 1400px) {
          padding: 2.5vw 15px 23px 15px;
          padding-right: 33.7%;
        }
        @include breakpoint(small only) {
          padding: 3vw 15px 10px 15px;
          padding-right: 35.7%;
        }
      }
    }
    dd {
      font-size: 15px;
      @include breakpoint(small down) {
        font-size: 0.7875rem;
      }
      @include breakpoint(large only) {
        font-size: 1.5vw;
      }
      a {
        padding: 0 15px 3vw 39px;
        @include breakpoint(large only) {
          padding: 0 15px 3vw 15px;
          padding-right: 31.7%;
        }
        @include breakpoint(xlarge only) {
          padding: 0 33% 2.6vw 40px;
        }
        @include breakpoint(xxlarge) {
          padding: 0 15px 3vw 39px;
          padding-right: 31.7%;
        }
        @include breakpoint(medium only) {
          padding: 0 15px 15px 15px;
          padding-right: 31.7%;
        }
        @media screen and (min-width: 1473px) and (max-width: 1627px) {}
        @media screen and (min-width: 1201px) and (max-width: 1400px) {
          padding: 0 15px 15px 15px;
          padding-right: 33.7%;
        }
        @include breakpoint(small only) {
          padding: 0 15px 15px 15px;
          padding-right: 35.7%;
        }
      }
    }
  }
  .l-banner-area {
    @include breakpoint(large down) {
      padding: 15px 0;
      img {
        width: 100%;
      }
    }
    @include breakpoint(xxlarge) {
      padding: 60px 0 60px 0;
      text-align: center;
    }
    @include breakpoint(xlarge only) {
      padding: 60px 0 45px 0;
      text-align: center;
    }
    .inner {
      @include breakpoint(large only) {
        width: 100%;
      }
    }
  }
  .l-news-area {
    @include breakpoint(xxlarge) {
      margin-bottom: 43px;
    }
    @include breakpoint(xlarge only) {
      margin-bottom: 30px;
    }
    .title {
      font-size: 1.5625rem;
      @include breakpoint(xlarge) {
        line-height: 1em;
        margin-bottom: 15px;
      }
    }
    .link {
      position: relative;
      font-size: 0.875rem;
      margin-bottom: 15px;
      &:after {
        @include cursor2;
        position: relative;
        top: -2px;
        bottom: 0;
        margin: auto;
        margin-left: 5px;
      }
    }
  }
  .news-list {
    @include breakpoint(large down) {
      margin-bottom: 20px;
    }
  }
  .news-bloc {
    padding-top: 10px;
    padding-bottom: 10px;
    word-break: break-all;
    @include breakpoint(medium down) {
      border-top: 1px solid #d0d0d0;
    }
    &:first-child {
      padding-top: 0;
      border-top: 0;
    }
    .contents {
      @include breakpoint(large) {
        padding-left: 4%;
      }
    }
  }
  .l-information-area {
    a.link-01,.information-banner-text{
      display: block;
      @include breakpoint(large down) {
        padding: 3.7vw 15px 3vw 15px;
        padding-right: 37.7%;
      }
      @include breakpoint(xlarge down) {
        //min-height: 15.5vw;
      }
      @include breakpoint(xlarge) {
        padding: 30px 45% 30px 40px;
      }
    }
    @include breakpoint(large down) {
      margin-bottom: 30px;
    }
    @include breakpoint(xlarge) {
      margin-bottom: 66px;
    }
    .inner {
      @include breakpoint(large) {
        width: 100%;
      }
    }
    .category {
      border-radius: 11px;
      padding: 3px 8px;
      top: -3px;
    }
    .information-banner-1,
    .information-banner-2,
    .information-banner{
      background: #fff;
      position:relative;
      img{
        position:absolute;
        right:0;
        bottom:0;
        height:100%;
      }
      @include breakpoint(large down) {
        margin-top: 5px;
      }
      .title {
        margin-bottom: 5px;
        position:relative;
        z-index:5;
        @include breakpoint(large down) {
          font-size: 1.25rem;
          line-height: 1em;
        }
        @include breakpoint(xxxlarge) {
          font-size: 1.875rem;
        }
        @include breakpoint(xlarge) {
          font-size: 1.5rem;
          margin-bottom: 10px;
        }
        font-weight: bold;
          color: $main_color;
        a {
          padding-bottom: 0;
          color: $main_color;
        }
      }
      .information-banner-text{
        position:relative;
        z-index:5;
          padding-top: 0;
      }
    }
    .information-banner-1 {
      background: url(../img/img_information_01.png) right bottom no-repeat #fff;
      background-size: contain;
    }
    .information-banner-2 {
      background: url(../img/img_information_02.png) right bottom no-repeat #fff;
      background-size: contain;
    }
  }
  .l-link-area {
    @include clearfix;
    @include breakpoint(large down) {
      padding-bottom: 35px;
    }
    @include breakpoint(xlarge) {
      margin-bottom: 69px;
    }
    dl {
      float: left;
      margin-left: 3%;
      img {
        width: 100%;
      }
      @include breakpoint(large) {
        width: 24.25%;
        margin-left: 1%;
        &:first-child {
          margin-left: 0;
        }
      }
      @include breakpoint(medium down) {
        width: 48.25%;
        margin-top: 7px;
        &:first-child,
        &:nth-child(2n+1) {
          margin-left: 0;
        }
        &:first-child,
        &:first-child+dl {
          margin-top: 0;
        }
      }
      @include breakpoint(xxlarge) {
        width: 22.7%;
        &:first-child,
        &:nth-child(4n+1) {
          margin-left: 0;
        }
      }
    }
    dt {
      margin-bottom: 2px;
      @include breakpoint(xxlarge) {
        font-size: 80%;
      }
      @media screen and (min-width: 1477px) {
        font-size: 15px;
      }
      @include breakpoint(xlarge down) {
        font-size: 85%;
      }
      @include breakpoint(medium down) {
        font-size: 90%;
      }
    }
    dd {
      background: #fff;
    }
  }
  .l-footer-bottom {
    @include breakpoint(xxlarge) {
      background: #fff;
    }
    @include breakpoint(xxlarge only) {
      padding-top: 10px;
      padding-bottom: 37px;
    }
    @include breakpoint(xxxlarge) {
      padding-top: 30px;
      padding-bottom: 37px;
    }
  }
  .l-sitemap-area {
    background: #fff;
    .sitemap-list {
      @include clearfix;
      @include breakpoint(xxxlarge) {
        padding-left: 20px;
        margin-bottom: 20px;
      }
      @include breakpoint(xxlarge down) {
        padding: 10px 0;
      }
      @include breakpoint(medium down) {
        padding: 0;
      }
    }
    ul {
      @include breakpoint(large only) {
        text-align: center;
      }
      @include breakpoint(xlarge only) {
        text-align: center;
      }
      @include breakpoint(xxlarge only) {
        text-align: center;
      }
      @include breakpoint(xxxlarge) {
        &:first-child+ul {
          margin-top: 20px;
        }
      }
      @include breakpoint(medium down) {
        width: 50%;
        float: left;
      }
    }
    li {
      @include breakpoint(large only) {
        display: inline-block;
      }
      @include breakpoint(xlarge only) {
        display: inline-block;
      }
      @include breakpoint(xxlarge only) {
        display: inline-block;
      }
      @include breakpoint(xxxlarge) {
        font-size: 1rem;
        margin-top: 20px;
        &:first-child {
          margin-top: 0;
        }
      }
      @include breakpoint(large down) {
        font-size: 0.8125rem;
      }
      @include breakpoint(medium down) {
        position: relative;
      }
      a {
        display: block;
        @include breakpoint(xxlarge down) {
          padding: 0 10px;
        }
        @include breakpoint(large down) {
          padding: 15px 10px;
        }
        @include breakpoint(medium down) {
          padding: 9px 10px 9px 14%;
        }
        &:before {
          @include cursor2;
          position: relative;
          top: -2px;
          margin-right: 10px;
          @include breakpoint(medium down) {
            position: absolute;
            top: 15px;
            left: 6%;
          }
        }
      }
    }
  }
  .l-sp-banner-area {
    padding: 15px 0;
    @include breakpoint(xxlarge) {
      display: none;
    }
    dl {
      text-align: center;
      background: #fff;
      border-radius: 6px;
      overflow: hidden;
      padding-bottom: 10px;
    }
    dt {
      color: #fff;
      font-weight: bold;
      font-size: 1.125rem;
      background: $main_color;
      padding: 10px 0;
      line-height: 1em;
    }
    .tel {
      padding-top: 15px;
      width: 77.2%;
      margin: 0 auto;
      padding-left: 9vw;
      background: url(../img/ico_tel.png) left 91% no-repeat;
      background-size: 7.25vw;
      margin-bottom: 5px;
      @include breakpoint(large) {
        background-size: 55px;
        padding-left: 70px;
        width: 52.2%;
      }
    }
    .time {
      font-size: 0.9375rem;
    }
  }
  .l-pc-banner-area {
    width: 91%;
    margin: 0 auto;
    @include breakpoint(xlarge down) {
      display: none;
    }
    &.visible {
      @include breakpoint(xlarge down) {
        display: block;
      }
    }
    dl {
      text-align: center;
      background: #fff;
      overflow: hidden;
      padding-bottom: 10px;
      border: 1px solid $main_color;
    }
    dt {
      color: #fff;
      font-weight: bold;
      font-size: 1.125rem;
      background: $main_color;
      padding: 10px 0;
      @include breakpoint(xlarge) {
        line-height: 1.25em;
      }
      @include breakpoint(xxlarge) {
        font-size: 17px;
      }
      @include breakpoint(large down) {
        line-height: 1em;
      }
    }
    .tel {
      padding-top: 15px;
      width: 85%;
      margin: 0 auto;
      padding-left: 30px;
      background: url(../img/ico_tel.png) left 14px no-repeat;
      background-size: 10.5%;
      @include breakpoint(xxlarge only) {
        padding-top: 10px;
        margin-bottom: 5px;
        background-position: left 104%;
        line-height: 1em;
      }
    }
    .time {
      font-size: 0.9375rem;
      @include breakpoint(xxlarge only) {
        font-size: 0.75rem;
      }
    }
  }
  .l-group-list-area {
    background: #fff;
    @include breakpoint(xlarge down) {
      padding: 20px 0;
    }
    @include breakpoint(xxlarge only) {
      padding: 10px 0 0 20px;
    }
    .inner {
      @include breakpoint(xxlarge) {
        width: 100%;
      }
    }
    .title {
      @include breakpoint(xlarge down) {
        text-align: center;
        margin-bottom: 10px;
      }
      @include breakpoint(xxlarge) {
        margin-bottom: 20px;
      }
    }
    img {
      @include breakpoint(medium only) {
        width: 40%;
      }
      @include breakpoint(small down) {
        width: 57.5%;
      }
    }
    .list-title {
      @include breakpoint(large) {
        font-size: 1.125rem;
        padding-bottom: 5px;
        border-bottom: 1px solid #d0d0d0;
        font-weight: bold;
      }
      @include breakpoint(medium down) {
        font-size: 1rem;
        padding: 12px 10px;
        background: #aec4e5;
        margin-top: 3px;
        position: relative;
        font-weight: bold;
        &:before {
          @include cursor2(9px, #788497, 135deg);
          position: absolute;
          right: 3%;
          top: -1px;
          bottom: 0;
          margin: auto;
        }
        &.is-open {
          &:before {
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 5px;
          }
        }
      }
    }
  }
  .group-list {
    .columns {
      .list-title {
        @include breakpoint(large) {
          margin-top: 20px;
          &:first-child {
            margin-top: 0;
          }
        }
      }
      &:first-child {
        .list-title {
          margin-top: 0;
        }
      }
    }
  }
  .group-list-contents {
    @include breakpoint(medium down) {
      display: none;
    }
    @include breakpoint(large){
      display:block !important;
    }
    dl {
      &:first-child {
        dt:first-child {
          border-top: 0;
          @include breakpoint(large) {
            padding-top: 9px;
          }
        }
      }
    }
    dt {
      padding-top: 7px;
      @include breakpoint(large) {
        font-size: 0.875rem;
      }
      @include breakpoint(medium down) {
        padding-top: 10px;
        font-size: 0.875rem;
        border-top: 1px solid #d0d0d0;
        padding-left: 3%;
        padding-right: 3%;
      }
    }
    dd {
      font-size: 0.6rem;
      @include breakpoint(medium down) {
        padding: 0 3%;
        padding-bottom: 10px;
      }
    }
  }
  .l-copyright-area {
    text-align: center;
    @include breakpoint(medium down) {
      padding: 15px 12%;
    }
    @include breakpoint(large) {
      font-size: 0.9375rem;
      padding: 24px 0 27px 0;
    }
    margin: 0 auto;
    background: #6088c6;
    color:#fff;
  }

  /* ==============================================
 下層ページ
=============================================== */
  .l-mainvisual {
    &.lower {
      text-align: center;
      font-size: 34px;
      color: #fff;
      text-shadow: 3px 1px 5px #6b6b6b;
      padding: 80px 0;
      @include breakpoint(small only) {
        padding: 45px 0;
        font-size: 19px;
        line-height: 1.25em;
      }
      @include breakpoint(large down) {
        position: relative;
        z-index: 1;
        margin-top: -7px;
      }
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      span {
        font-size: 28px;
        @include breakpoint(small only) {
          font-size: 15px;
        }
      }
    }
    &.p11 {
      background-image: url(../img/page/mainvisual_p11.png);
    }
  }

  .l-page-navi {
    padding: 10px 0 0 0;
    @include breakpoint(medium down) {
      padding: 20px 3%;
      text-align: center;
    }
    background: #aec4e5;
    text-align: center;
    font-size: 0;
    .page-navi-wrap {
      @include breakpoint(large) {

        display: inline-block;
      }
    }
    ul {
      text-align: center;
      @include breakpoint(large) {
        display: table;
        border-spacing: 10px 0;
      }
    }
    li {
      @include breakpoint(large) {
        display: table-cell;
        vertical-align: middle;
      }
      @include breakpoint(medium down) {
        margin-top: 5px;
        br {
          display: none;
        }
        &:first-child {
          margin-top: 0;
        }
      }
      background-color: #6088c6;
      font-weight: bold;
      text-align: center;
      font-size: 14px;
      min-height: 2em;
      a {
        color: #fff;
        padding: 10px 0;
        @include breakpoint(large) {
          padding-left: 20px;
          padding-right: 20px;
        }
        @include breakpoint(xxlarge) {
          width: 200px;
        }
        display: block;
        @include breakpoint(medium only) {
          font-size: 14px;
        }
        @include breakpoint(small down) {
          line-height: 1.25em;
        }
      }
      &.nav-path-selected {
        a {
          color: #333;
        }
        background-color: #f5f5f5;
      }
    }
  }

  .l-page-contents {
    background-color: #f5f5f5;
    padding-top: 40px;
    padding-bottom: 65px;
    &.wrap {
      padding-top: 0;
    }
    @include breakpoint(small only) {
      padding-bottom: 35px;
    }
    .title {
      font-size: 26px;
      margin-bottom: 32px;
      line-height: 1em;
      @include breakpoint(medium down) {
        font-size: 20px;
      }
    }
    .page-contents-information {
      padding: 24px 30px 29px 30px;
      @include breakpoint(small only) {
        padding: 25px 5%;
      }
      background: #fff;
      margin: 22px 0 30px 0;
      &.check-list-information {
        padding-bottom: 22px;
      }
    }
    .point-title {
      font-size: 22px;
      margin-bottom: 16px;
      @include breakpoint(small only) {
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
    .contents-box-wrap {
      @include clearfix;
      @include breakpoint(xlarge down) {
        width: 100%;
      }
    }
    .page-contents-text {
      line-height: 1.75em;
      .txt {
        margin-top: 16px;
        @include breakpoint(small only) {
          margin-top: 10px;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }
    .l-section {
      text-align: center;
      padding: 20px 0 0 0;
      .contents-box-wrap {
        display: table;
        margin: 0 auto;
        @include breakpoint(xlarge down) {
          display: block;
        }
        .box {
          display: table-cell;
          vertical-align: middle;

          &:first-child {
            padding-left: 0;
            @include breakpoint(xlarge down) {
              margin-top: 0;
            }
          }
          @include breakpoint(xxlarge) {
            padding-left: 10px;
          }
          @include breakpoint(xlarge down) {
            display: block;
            margin-top: 15px;
            padding-left: 0;
          }
        }
      }
    }
    /* ==============================================
       p11
      =============================================== */
    .p11-contents-01 {
      text-align: center;
      padding: 20px 0;
      [class^="box-"] {
        float: left;
        @include breakpoint(xxlarge) {
          margin-left: 5px;
        }
        @include breakpoint(xlarge down) {
          margin-left: 1%;
        }
        dt {
          font-size: 16px;
          font-weight: bold;
          @include breakpoint(large only) {
            font-size: 2vw;
          }
          @include breakpoint(medium only) {
            font-size: 16px;
          }
          @include breakpoint(small down) {
            font-size: 2.5vw;
          }
        }
        dd {
          font-size: 34px;
          font-weight: bold;
          @include breakpoint(medium down) {
            font-size: 3.5vw;
          }
        }
        &:first-child {
          margin-left: 0;
        }
      }
      .box-01,
      .box-03,
      .box-05 {
        @include breakpoint(xlarge down) {
          width: 30%;
        }
        dl {
          background: #fff;
        }
        dt,
        dd {
          padding: 10px 40px;
          @include breakpoint(large down) {
            padding: 5px 3%;
          }
          .mini {
            font-size: 1rem !important;
          }
        }
      }
      .box-01 {
        dl {
          margin-bottom: 7px;
        }
        dt {
          background: #d0d0d0;
        }
      }
      .box-03 {
        dt {
          background: #aec4e5;
        }
      }
      .box-05 {
        dl {
          margin-bottom: 7px;
        }
        dt {
          background: #6088c6;
          color: #fff;
        }
      }
      .box-02,
      .box-04 {
        font-size: 50px;
        line-height: 1em;
        padding-top: 25px;
        @include breakpoint(xlarge down) {
          font-size: 3vw;
        }
      }
    }
    .p11-contents-02 {
      text-align: center;
      padding: 20px 0;
      [class^="box-"] {
        float: left;
      }
      .box-01 {
        background: #fff;
        padding: 9px 20px 13px 20px;
        position: relative;
        text-align: left;
        @media screen and (min-width: 1197px) and (max-width: 1400px) {
          width: 36%;
        }
        @include breakpoint(xlarge down) {
          width: 100%;
          margin-bottom: 20px;
        }
        &:after {
          @include cursor(#fff, 12px, 12px, 16px);
          position: absolute;
          top: 0;
          right: -16px;
          bottom: 0;
          margin: auto;
          @include breakpoint(xlarge down) {
            transform: rotate(90deg);
            left: -53%;
            bottom: -107px;
          }
        }
        dt {
          font-size: 22px;
          font-weight: bold;
          @include breakpoint(xlarge down) {
            font-size: 1rem;
          }
          .strong {
            font-size: 38px;
            padding-left: 5px;
            @include breakpoint(xlarge down) {
              font-size: 1.6rem;
            }
          }
        }
        dd {
          span {
            font-size: 22px;
            font-weight: bold;
            @include breakpoint(xlarge down) {
              font-size: 1.3rem;
            }
          }
        }
      }
      .box-02 {
        font-size: 28px;
        background: url(../img/page/bg_11.png) no-repeat;
        background-size: 100%;
        width: 248px;
        text-align: center;
        padding: 27px 0;
        margin-left: 30px;
        @include breakpoint(xlarge down) {
          margin-left: 0;
          width: 40.5%;
          font-size: 3.5vw;
          padding: 4vw 0 4vw 0;
        }
        @include breakpoint(large down) {
          width: 48.5%;
          padding: 5vw 0 7vw 0;
        }
        @include breakpoint(small down) {
          width: 43%;
          padding: 4vw 0;
        }
        .txt {
          display: inline-block;
          padding: 8px 15px;
          background-color: #fff;
          -ms-filter: "alpha(opacity=80)";
          background: rgba(255, 255, 255, 0.8);
        }
      }
      .box-03 {
        font-size: 50px;
        line-height: 1em;
        padding-top: 25px;
        @include breakpoint(xlarge down) {
          padding-top: 4.5vw;
        }
        @include breakpoint(large down) {
          padding-top: 4.5vw;
          font-size: 7vw;
        }
      }
      .box-04 {
        line-height: 1em;
        padding-top: 30px;
        font-size: 44px;
        @media screen and (min-width: 1197px) and (max-width: 1400px) {
          font-size: 3vw;
        }
        @include breakpoint(xlarge down) {
          padding-top: 5vw;
        }
        @include breakpoint(large down) {
          padding-top: 6vw;
          width: 43%;
          font-size: 3rem;
          text-align: left;
        }
        @include breakpoint(medium down) {
          font-size: 6vw;
        }
        @include breakpoint(small down) {
          padding-top: 6vw;
          width: 50%;
          font-size: 7vw;
        }
        .mini {
          font-size: 50% !important;
        }
      }
    }
    /* ==============================================
       p12
      =============================================== */
    .title+.sub-title {
      margin-top: 0;
    }
    .sub-title {
      @include breakpoint(large) {
        padding-left: 15px;
        font-size: 22px;
      }
      @include breakpoint(medium down) {
        padding-left: 10px;
        font-size: 16px;
      }
      border-left: 4px solid $main_color;
      line-height: 1em;
      margin-bottom: 25px;
      margin-top: 60px;
      @include breakpoint(small only) {
        margin-top: 35px;
        margin-bottom: 15px;
      }
      &:first-child {
        margin-top: 0;
      }
    }
    .sub-title-level2 {
      font-weight: bold;
      margin-bottom: 20px;
      @include breakpoint(large) {
        font-size: 110%;
      }
      @include breakpoint(medium down) {
        font-size: 16px;
        margin-bottom: 15px;
      }
      line-height: 1em;
    }
    .sub-title-level3 {
      font-size: 24px;
      margin-bottom: 30px;
      @include breakpoint(small only) {
        margin-bottom: 12px;
      }
      line-height: 1em;
      text-align: left;
    }
    .p12-contents-01 {
      text-align: center;
      padding: 30px 0 50px 0;
      @include breakpoint(small only) {
        padding-bottom: 30px;
      }
    }
    .p12-contents-02 {
      text-align: center;
      padding: 0 0 40px 0;
      @include breakpoint(small only) {
        padding-bottom: 20px;
      }
      .contents-box-wrap {
        display: table;
        margin: 0 auto;
        .box {
          display: table-cell;
          vertical-align: middle;
          &:first-child {
            padding-left: 0;
          }
          @include breakpoint(xxlarge) {
            padding-left: 5px;
          }
          @include breakpoint(xlarge down) {
            padding-left: 1%;
          }
        }
      }
    }

    .p12-contents-03 {
      .box {
        @include breakpoint(xlarge down) {
          margin-top: 10px;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }

    .p12-contents-06 {
      .contents-box-wrap {
        @include breakpoint(xlarge down) {
          .box {
            &:first-child {}
          }
        }
      }
    }

    .p12-contents-07 {
      margin-bottom: 40px;
    }

    .p12-contents-01 {
      .contents-box-wrap {
        text-align: center;
        .img {
          @media screen and (min-width: 535px) and (max-width: 1000px) {
            width:70%;
            margin: 0 auto;
          }
        }
      }
    }

    .p12-contents-02 {
      .contents-box-wrap {
        text-align: center;
        .box {
          @media screen and (min-width: 535px) and (max-width: 1000px) {
            display: inline-block;
            margin-top: 0;
            vertical-align: middle;
            img {}
            &:first-child {
              vertical-align: top;
              width: 221px;
            }
            &:first-child+.box {
              width: 10%;
              position: relative;
              top: 220px;
            }
            &:first-child+.box+.box {
              vertical-align: top;
              width: 228px;
            }
          }
        }
      }
    }

    .p12-contents-01,
    .p12-contents-03,
    .p12-contents-04,
    .p12-contents-05,
    .p12-contents-06,
    .p12-contents-07 {
      .box {
        @media screen and (min-width: 535px) and (max-width: 1000px) {
          width:65%;
          margin: 0 auto;
        }
      }
    }

    /* ==============================================
       p13
      =============================================== */
    .p13-contents-01 {
      margin-bottom: 40px;
      padding-top: 45px;
    }
    .p13-contents-02 {
      margin-bottom: 58px;
      @include breakpoint(small only) {
        margin-bottom: 30px;
      }
      padding: 0;
    }
    .p13-contents-03 {
      margin-bottom: 60px;
      @include breakpoint(small only) {
        margin-bottom: 10px;
      }
      padding: 0;
    }
    .p13-contents-04 {
      padding-top: 0;
    }
    .p13-contents-05 {
      padding: 0;
      padding-top: 40px;
      @include breakpoint(large) {
        width: 830px;
      }
      @include breakpoint(small only) {
        padding-top: 20px;
      }
      margin: 0 auto;
      .p13-content-table {
        margin-bottom: 2px;
        dl {
          display: table;
          width: 100%;
          margin-top: 1px;
          &:first-child {
            margin-top: 0;
          }
        }
        dt,
        dd {
          padding: 13px 15px;
          text-align: left;
        }
        dt {
          display: table-cell;
          background: #aec4e5;
          width: 85px;
          @include breakpoint(small only) {
            vertical-align: middle;
            width: 105px;
          }
        }
        dd {
          display: table-cell;
          background: #fff;
          width: 745px;
        }
      }
      .p13-content-comment {
        background: #d9e1ef;
        padding: 20px 20px 30px 20px;
        @include breakpoint(small only) {
          padding: 10px 3%;
        }
        dt {
          margin-bottom: 5px;
        }
      }
    }

    .p13-contents-06 {
      padding-top: 40px;
      @include breakpoint(small only) {
        padding-top: 20px;
      }
      padding-bottom: 0;
    }
    .p13-contents-07 {
      padding-top: 35px;
      padding-bottom: 0;
    }

    .p13-contents-08 {
      margin-bottom: 40px;
      padding-top: 40px;
      @include breakpoint(small only) {
        padding-top: 20px;
        margin-bottom: 20px;
      }
      .contents-box-wrap {
        .box {
          vertical-align: bottom;
        }
      }
    }

    .p13-contents-09 {
      margin-bottom: 25px;
      padding-top: 40px;
      @include breakpoint(small only) {
        padding-top: 20px;
      }
    }

    .p13-contents-10 {
      padding: 0;
    }

    .p13-contents-01,
    .p13-contents-02,
    .p13-contents-03,
    .p13-contents-04,
    .p13-contents-06,
    .p13-contents-07,
    .p13-contents-08,
    .p13-contents-09,
    .p13-contents-10 {
      .box {
        @media screen and (min-width: 535px) and (max-width: 1000px) {
          width:65%;
          margin: 0 auto;
        }
      }
    }

    .p13-contents-05 {
      @media screen and (min-width: 535px) and (max-width: 1000px) {
        width:65%;
        margin: 0 auto;
      }
    }


    /* ==============================================
       p21
      =============================================== */
    .p21-contents-01 {
      padding-top: 50px;
    }
    /* ==============================================
       p22
      =============================================== */
    .p22-contents-01 {
      width: 260px;
      margin: 0;
      @include breakpoint(small only) {
        margin: 0 auto;
      }
    }

    .p22-contents-02 {
      .page-contents-text {
        @include clearfix;
      }
      &-left {
        float: left;
        width: 79.6%;
        .txt {
          margin-top: 20px;
        }
        .bnr {
          padding-top: 14px;
        }
      }
      &-right {
        float: right;
        width: 19.9%;
        text-align: center;
        font-size: 80%;
        a {
          display: block;
          color: $strong-color;
        }
      }
    }

    .checklist-title {
      font-size: 18px;
      margin-bottom: 11px;
    }

    .check-list {
      li {
        padding-left: 25px;
        background: url(../img/ico_check.png) left 5px no-repeat;
        line-height: 29px;
      }
    }
    /* ==============================================
       p23
      =============================================== */
    .p23-contents-01 {
      .sub-title {
        margin-top: 40px;
      }
      .page-contents-information {
        margin-bottom: 40px;
        .check-list {
          margin-bottom: 10px;
        }
      }
    }

    .p23-contents-02 {
      .sub-title {
        margin-bottom: 33px;
      }
    }

    .btn {
      background: #4072b3;
      font-weight: bold;
      display: inline-block;
      position: relative;
      transition: .4s;
      &:hover {
        opacity: .7;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
      }
      &.navy {
        background-color: #1d2b77;
      }
      a,
      button {
        color: #fff;
        display: block;
        padding: 10px 20px;
        &:after {
          @include cursor2(8px, #fff);
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 12px;
          border-top-width: 2px;
          border-right-width: 2px;
        }
      }
    }

    .center-list {
      text-align: center;
      li {
        margin-left: 17px;
        &:first-child {
          margin-left: 0;
        }
      }
      .btn {
        a {
          padding: 17px 45px;
        }
      }
      @media screen and (max-width: 823px) {
        li {
          margin-left: 0;
          width: 90%;
          margin: 0 auto;
          margin-top: 10px;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
    /* ==============================================
       p25
      =============================================== */
    .group-site-list {
      @include clearfix;
      margin-top: 20px;
      &:first-child {
        margin-top: 0;
      }
      .group-site-title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 5px;
        @include breakpoint(medium down) {
          font-size: 1rem;
        }
      }
      .group-site-box-top {
        background: #aec4e5;
        padding: 15px 20px;
        padding-right: 132px;
      }

      .group-site-box {
        position: relative;
        background: #fff;
        @include breakpoint(medium down) {
          margin-bottom: 20px;
        }
        &.link-empty {
          padding-bottom: 56px;
        }
        &.image-empty {
          .group-site-box-top {
            padding-right: 20px;
          }
        }
        .photo {
          position: absolute; //width: 17.76%;
          width: 112px;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 3%;
          height: 112px;
          background: #d0d0d0;
          img {
            max-width: 112px;
            max-height: 112px;
            @include position-center;
          }
        }
        @include breakpoint(large) {
          float: left;
          width: 48.5%;
          margin-left: 3%;
          margin-top: 30px;
          &:first-child,
          &:first-child+.group-site-box {
            margin-top: 0;
          }
          &:first-child,
          &:nth-child(2n+1) {
            margin-left: 0;
          }
        }
        .link {
          background: #fff;
          a {
            display: block;
            padding: 17px 20px;
            &:after {
              @include cursor2(9px);
              border-top-width: 2px;
              border-right-width: 2px;
              margin-left: 5px;
              top: -1px;
              position: relative;
            }
          }
        }
      }
    }
    /* ==============================================
       p30
      =============================================== */
    .p30-wrap {
      @include clearfix;
    }
    .p30-title-comment {
      font-size: 26px;
      margin-bottom: 25px;
      @include breakpoint(medium down) {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
    .p30-photo {
      @media screen and (min-width: 870px) {
        float: left;
        width: 33.7%;
      }
      .btn-wrap {
        text-align: center;
      }
      @media screen and (max-width: 1866px) {
        float: none;
        width: 438px;
        margin: 0 auto;
      }
      @media screen and (max-width: 481px) {
        width: auto;
      }
      .title {
        font-size: 34px;
        margin-bottom: 37px;
        text-align: left;
        @include breakpoint(medium down) {
          font-size: 26px;
        }
      }
      .photo {
        position: relative;
        margin-bottom: 20px;
        width: 100%;
        height: 548px;
        @include breakpoint(small only) {
          margin: 0 auto;
          margin-bottom: 20px;
          width: 95%;
        }
        &.is-soldout {
          &:before {
            content: "";
            width: 118px;
            height: 118px;
            background: url(../img/page/ico_p30_01.png) no-repeat;
            background-size: 100%;
            position: absolute;
            z-index: 10;
            left: -30px;
            top: -22px;
            @include breakpoint(small only) {
              width: 24vw;
              height: 24vw;
              left: -10px;
              top: -10px;
            }
          }
        }
        @media screen and (max-width: 481px) {
          padding-top: 122.6%;
          height: auto;
        }
        img {
          @include position-center;
          max-width: 100%;
          max-height: 548px;
        }
      }
      .btn {
        display: inline-block;
        line-height: 1em;
        a {
          padding: 23px 67px;
        }
      }
    }
    .p30-detail {
      float: right; //width:62.3%;
      @media screen and (min-width: 870px) {
        width:810px;
      }
      @media screen and (max-width: 1866px) {
        float: none;
        width: 100%;
        margin-top: 30px;
      }
      table {
        width: 100%;
        display: table;
      }
      th,
      td {
        padding: 12px 14px;
        display: table-cell;
        vertical-align: middle;
        @media screen and (max-width: 871px) {
          display:block;
        }
      }
      th {
        background: #aec4e5;
        border-top: 1px solid #fff;
        font-weight: bold;
        @media screen and (min-width: 870px) {
          width:170px;
        }
        @media screen and (max-width: 871px) {}
      }
      td {
        border-top: 1px solid #d0d0d0;
        background: #fff;
      }
      tr:first-child {
        th,
        td {
          border-top: 0;
        }
      }
    }
    .p30-detail-column-2 {
      td {
        @media screen and (min-width: 870px) {
          width:233px;
        }
      }
      &:first-child {
        th {
          border-top: 1px solid #fff;
          &:first-child+dd+dt {
            border-top: 1px solid #d0d0d0;
          }
        }
        td {
          border-top: 1px solid #d0d0d0;
        }
      }
    }
    .p30-detail-top {
      @include clearfix;
      .column-01 {
        float: left;
        width: 49.5%;
      }
      .column-02 {
        float: right;
        width: 49.5%;
        margin-left: 1%;
      }
    }
    .p30-detail-main {
      margin-top: 20px;
    }
    /* ==============================================
       p40
      =============================================== */
    .p40-contents-01 {
      margin-bottom: 25px;
    }
    .p40-contents-01-btn {
      margin-bottom: 80px;
    }
    .p40-contents-02 {
      .sub-title {
        margin-top: 33px;
      }
      .details {
        dl {
          display: table;
          width: 100%;
          dt {
            border-top: 1px solid #fff;
            width: 144px;
            @media screen and (max-width: 454px) {
              width:20%;
            }
          }
          dd {
            border-top: 1px solid #d0d0d0;
          }
          &:first-child {
            dt,
            dd {
              border-top: 0;
            }
          }
        }
        dt,
        dd {
          display: table-cell;
          padding: 10px 16px;
        }
        dt {
          font-weight: bold;
          background: #aec4e5;
        }
        dd {
          background: #fff;
        }
        .btn {
          display: block;
          margin: 11px 0 17px 0;
          a {
            padding: 5px 15px;
            font-weight: normal;
          }
          width:155px;
          @media screen and (max-width: 454px) {
            width: 80%;
          }
        }
      }
      .accessmap {
        dd {
          background: url(../img/page/img_p40_01.png) 97% no-repeat #fff;
          @media screen and (max-width: 815px) {
            background-position: 10px 10px;
            padding-top: 150px;
          }
        }
      }
    }

    .p40-contents-03 {
      padding-top: 30px;
    }
    /* ==============================================
       p50,p51tp
      =============================================== */
    .blog-list {
      @include clearfix;
      padding: 40px 0;
    }

    .blog-box-wrap {
      padding-left: 3px;
    }

    .blog-status {
      margin-bottom: 10px;
      line-height: 1em;
      margin-left: -25px;
    }

    .blog-category {
      background: #6088c6;
      color: #fff;
      padding: 4px 15px;
      display: inline-block;
      position: relative;
      @media screen and (min-width: 404px) and (max-width: 501px) {
        margin-bottom: 10px;
      }
      &:before {
        @include cursor($size1: 3px, $size2:3px, $size3:3px);
        position: absolute;
        left: 2px;
        bottom: -4px;
        transform: rotate(-45deg);
      }
    }

    .blog-date {
      display: inline-block;
      padding-left: 10px;
      color: #7d7d7d;
      @media screen and (min-width: 404px) and (max-width: 501px) {
        padding-left: 24px;
      }
    }

    .blog-box {
      background: #fff;
      padding: 21px;
      &.detail {
        width: 100%;
        float: none;
        padding: 29px 30px;
        margin-bottom: 40px;
        .blog-status {
          margin-bottom: 12px;
          margin-left: -35px;
        }
        .blog-category {
          padding-top: 6px;
          padding-bottom: 6px;
          @media screen and (min-width: 404px) and (max-width: 501px) {
            margin-bottom: 0;
          }
        }
        .blog-title {
          font-size: 28px;
          margin-bottom: 20px;
          @media screen and (min-width: 404px) and (max-width: 501px) {
            line-height:1.25em;
            margin-bottom: 10px;
          }
        }
        .blog-comment {
          p {
            margin-top: 30px;
            @media screen and (min-width: 404px) and (max-width: 501px) {
              margin-top: 20px;
            }
            &:first-child {
              margin-top: 0;
            }
          }
          img {
            padding: 20px 20px;
          }
        }
      }
      @media screen and (min-width: 404px) {
        margin-top: 30px;
        margin-left: 3.5%;
        float: left;
        width: 48.25%;
        &:first-child,
        &:first-child+.blog-box {
          margin-top: 0;
        }
        &:first-child,
        &:nth-child(2n+1) {
          margin-left: 0;
        }
      }
      @media screen and (max-width: 405px) {
        margin-top: 15px;
        &:first-child {
          margin-top: 0;
        }
      }

      .blog-title {
        font-size: 20px;
        font-weight: bold;
        color: $strong_color;
        margin-bottom: 9px;
      }
    }

    .page-counter {
      visibility: hidden;
      height: 0;
      overflow: hidden;
    }


    .ccm-pagination-wrapper {
      text-align: center;
      .pagination {
        text-align: center;
        line-height: 1em;
        display: inline-block;
      }
      li {
        margin-left: 10px;
        @media screen and (max-width: 430px) {
          margin-top:10px;
        }
        background: #fff;
        &:first-child {
          margin-left: 0;
        }
      }
      li,
      .page-counter {
        color: #6088c6;
        display: inline-block;
        border: 1px solid #d0d0d0;
      }
      .page-counter {
        padding: 7px 11px 6px 11px;
        border: 1px solid #6088c6;
        box-sizing: content-box;
        margin-right: 10px;
        visibility: hidden;
      }
      .active {
        border: 1px solid #6088c6;
        box-sizing: content-box;
      }
      .active,
      .disabled {
        padding: 10px 0;
        width: 2.4em;
        text-align: center;
      }
      .disabled {
        display: none;
      }
      .prev.disabled {
        &:first-child+li {
          margin-left: 0;
        }
      }
      a {
        display: block;
        padding: 10px 0;
        width: 2.4em;
        text-align: center;
        color: #6088c6;
      }
      .page-counter,
      .active {
        background: #6088c6;
        color: #fff;
      }
      .active {
        font-weight: bold;
      }
    }
    .blog-btn,
    .seminar-btn {
      width: 260px;
      margin: 0 auto;
      display: block;
      text-align: center;
      a {
        padding: 17px 0;
        &:after {
          content: none;
        }
      }
    }
    .seminar-btn {
      margin-top: 20px;
    }
    /* ==============================================
       p61
      =============================================== */
    .p61-contents-01,
    .p61-contents-02 {
      padding-bottom: 32px;
      .signature {
        text-align: right;
        .name {
          padding-top: 10px;
        }
      }
    }
    .p61-contents-01 {
      background-image: url(../img/page/img_p61_01.png);
      background-repeat: no-repeat;
      background-position: 30px 30px;
      padding-top: 33px;
      padding-left: 295px;
      @media screen and (max-width: 525px) {
        background-size: 61%;
        background-position: center 8vw;
        padding-top: 95%;
        padding-left: 5%;
      }
      @media screen and (min-width: 526px) and (max-width:710px) {
        background-size: 238px;
        background-position: center 35px;
        padding-top: 380px;
        padding-left: 30px;
      }
      .belief {
        padding-top: 23px;
        padding-left: 1em;
        ul {
          padding-top: 23px;
          padding-left: 1em;
        }
      }
      .signature {
        @media screen and (min-width: 1025px) {}
      }
    }
    .p61-contents-02 {
      text-indent: 1em;
    }
    /* ==============================================
       p63
      =============================================== */
    .p63-contents-01 {
      @include clearfix;
      margin-bottom: 80px;
      .column-01 {
        width: 48.5%;
        float: left;
        @include breakpoint(xlarge down) {
          width: 100%;
          float: none;
        }
        td {
          padding: 10px 14px;
        }
        tr:first-child {
          th,
          td {
            border-top: 0;
          }
        }
      }
      .column-02 {
        width: 48.5%;
        float: right;
        @include breakpoint(xlarge down) {
          width: 100%;
          float: none;
        }
        .column-bottom {
          td {
            padding-bottom: 30px;
            line-height: 1.6;
          }
        }
        @include breakpoint(xxlarge) {
          tr:first-child {
            th,
            td {
              border-top: 0;
            }
          }
        }
      }
      table {
        width: 100%;
        display: table;
      }
      th,
      td {
        padding: 12px 14px;
        display: table-cell;
        @media screen and (max-width: 871px) {
          display:block;
        }
      }
      th {
        background: #aec4e5;
        border-top: 1px solid #fff;
        font-weight: bold;
        @media screen and (min-width: 870px) {
          width:170px;
        }
        @media screen and (max-width: 871px) {}
      }
      td {
        border-top: 1px solid #d0d0d0;
        background: #fff;
      }
    }

    .googlemap {
      @media screen and (max-width: 1914px) {
        position: relative;
        width: 100%;
        padding: 75% 0 0 0;
      }
      iframe {
        border: 0;
        @media screen and (max-width: 1914px) {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    /* ==============================================
       p70
      =============================================== */
    .news-list {
      padding: 40px 0;
    }

    .news-box {
      background: #fff;
      padding: 21px 21px 29px 21px;
      margin-top: 30px;
      &:first-child {
        margin-top: 0;
      }
      .news-date {
        color: #7d7d7d;
        margin-bottom: 10px;
      }
      .news-title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 9px;
      }
    }

    /* ==============================================
       p41,42
      =============================================== */
    .seminar-title {
      line-height:1.3em;
      .category {
        margin-left: 10px;
        top: -3px;
        display: inline-block;
        line-height:1em;
        @include breakpoint(medium down) {
          font-size: 1rem;
          padding: 3px 10px;
        }
        @include breakpoint(small only) {
        }
      }
    }
    .seminar-list {
      @include clearfix;
      margin-top: 20px;
      margin-bottom: 40px;
      &:first-child {
        margin-top: 0;
      }
      .seminar-title {
        font-size: 18px;
        @include breakpoint(medium down) {
          font-size: 1rem;
        }
      }
      .seminar-box-top {
        background: #aec4e5;
        padding: 15px 20px;
      }

      .seminar-box-main {
        padding: 20px;
        position: relative;
        .photo {
          position: absolute;
          top: 20px;
          left: 20px;
          width: 126px;
          height: 169px;
          img {
            @include position-center;
            max-width: 126px;
            max-height: 169px;
          }
        }
        .detail-area {
          min-height: 169px;
          padding-left: 145px;
          &.image-empty {
            padding-left: 0;
          }
          @include breakpoint(small only) {
            min-height: 254px;
            padding-bottom: 60px;
          }
          .area {
            font-size: 20px;
          }
          .date {
            font-size: 24px;
          }
          .time {
            font-size: 18px;
            margin-bottom: 35px;
          }
          .btn {
            width: 100%;
            @include breakpoint(small only) {
              position: absolute;
              left: 0;
              right: 0;
              width: 80%;
              margin: 0 auto;
              bottom: 20px;
            }
          }
        }
      }

      .seminar-box {
        position: relative;
        background: #fff;
        @include breakpoint(medium down) {
          margin-top: 20px;
          &:first-child {
            margin-top: 0;
          }
        }
        @include breakpoint(large) {
          float: left;
          width: 48.5%;
          margin-left: 3%;
          margin-top: 30px;
          &:first-child,
          &:first-child+.seminar-box {
            margin-top: 0;
          }
          &:first-child,
          &:nth-child(2n+1) {
            margin-left: 0;
          }
        }
        .link {
          background: #fff;
          a {
            display: block;
            padding: 17px 20px;
            &:after {
              @include cursor2(9px);
              border-top-width: 2px;
              border-right-width: 2px;
              margin-left: 5px;
              top: -1px;
              position: relative;
            }
          }
        }
      }
    }
    /* ==============================================
       p24
      =============================================== */
    .p24-contents-01 {
      padding-top: 33px;
      @media screen and (min-width: 450px) and (max-width: 551px) {
        width:80%;
        table {
          width: 100%;
        }
      }
      @media screen and (min-width: 550px) and (max-width: 900px) {
        width: 400px;
        table {
          width: 100%;
        }
      }
      @media screen and (min-width: 899px) and (max-width: 961px) {
        width: 420px;
        table {
          width: 100%;
        }
      }
      th {
        @media screen and (max-width: 850px) {
          width: 35%;
        }
      }
    }
    .p24-contents-01,
    .p24-contents-02 {
      .number {
        font-size: 25px;
        @media screen and (max-width: 385px) {
          font-size:17px;
        }
      }
      table {
        margin-bottom: 15px;
      }
      @include table-layout;
      th,
      td {
        padding: 12px 15px 14px 15px;
        @include breakpoint(xlarge down) {
          padding-left: 3%;
          padding-right: 3%;
        }
      }
      ol {
        @include clearfix;
        li {
          width: 50%;
          float: left;
        }
        .first {
          width: 53%;
          position: relative;
          padding-right: 22px;
          text-align: right;
          &:after {
            content: "～";
            padding-left: 10px;
            position: absolute;
            right: 0;
          }
        }
        .last {
          width: 43%;
          text-align: right;
          float: right;
          margin-right: 3%;
        }
      }
    }

    .p24-contents-02 {
      .contents-box-wrap {
        display: block;
      }
      table {
        line-height: 1.5em;
        @include breakpoint(xlarge) {
          width: 100%;
        }
      }
      td {
        padding: 11px 10px 10px 10px;
      }
      .table-title {
        text-align: left;
        font-size: 18px;
        margin-bottom: 14px;
        font-weight: bold;
      }
      th {
        text-align: center;
      }
      .contents-box-wrap {
        .box {
          vertical-align: top;
          padding-left: 34px;
          @media screen and (min-width: 450px) and (max-width: 551px) {
            width:80%;
            table {
              width: 100%;
            }
          }
          @media screen and (min-width: 550px) and (max-width: 900px) {
            width: 400px;
            table {
              width: 100%;
            }
          }
          @media screen and (min-width: 899px) and (max-width: 961px) {
            width: 420px;
            table {
              width: 100%;
            }
          }
          @media screen and (min-width:960px) and (max-width:1834px) {
            width: 450px;
            table {
              width: 100%;
            }
            th,
            td {
              padding-left: 5px;
              padding-right: 5px;
            }
          }
        }
      }
      .column-01 {
        @media screen and (max-width: 850px) {
          width: 57%;
        }
      }
      .right-column {}
      .table-01 {
        @include breakpoint(xlarge) {
          width: 32%;
        }
        @media screen and (min-width: 450px) and (max-width: 896px) {
          td {
            text-align: right;
          }
        }
      }
      .table-02 {
        .column-01 {
          width: 252px;
        }
        .column-02 {
          width: 165px;
        }
      }
      .table-03 {}
      .contents-box-wrap {
        @media screen and (max-width: 1731px) {
          display:block;
        }
        .table-01,
        .table-02 {
          @media screen and (min-width: 897px) {
            display:table-cell;
          }
          @media screen and (max-width:898px) {
            padding-left: 0;
          }
        }
        .table-03 {
          @media screen and (max-width: 1731px) {
            display:block;
            padding-left: 0;
            padding-top: 20px;
          }
          td {
            width: 265px;
          }
          .column-01,
          .column-02 {
            th {
              @include breakpoint(small only) {
                width: 40%;
              }
            }
          }
        }
        .table-01,
        .table-02,
        .table-03 {
          @include breakpoint(small only) {
            th,
            td {
              padding-left: 1%;
              padding-right: 1%;
            }
          }
        }
      }
    }

    /* ==============================================
       p43tp
      =============================================== */
    .p43tp-contents-01 {
      margin-bottom: 71px;
      .title {
        margin-bottom: 20px;
        line-height:1.3em;
        .category {
        margin-left: 10px;
        top: -3px;
        display: inline-block;
        line-height:1em;
        @include breakpoint(medium down) {
          font-size: 1rem;
          padding: 3px 10px;
        }
        @include breakpoint(small only) {
        }
      }
      }
      .page-contents-information {
        padding: 9px 15px;
        font-size: 18px;
        display: inline-block;
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
    .p43-seminar-title {
      font-size: 22px;
      position: relative;
      @include breakpoint(medium down) {
        font-size: 1rem;
      }
      .category {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 2em;
        background: #fff;
        color: $strong_color;
        @include breakpoint(large down) {
          font-size: 1rem;
          height: 1.75em;
        }
      }
      .text {
        display: block;
        padding-left: 130px;
        @include breakpoint(medium down) {
          padding-left: 110px;
        }
      }
      @include clearfix;
    }
    .p43-seminar-box-top {
      background: #aec4e5;
      padding: 8px 20px;
    }

    .p43-seminar-box-main {
      @include clearfix;
      .photo {
        @include breakpoint(large) {
          float: left;
        }
        @include breakpoint(medium down) {
          margin: 0 auto;
          margin-top: 20px;
        }
        width: 209px;
        height: 278px;
        position: relative;
        img {
          @include position-center;
          max-width: 209px;
          max-height: 278px;
        }
      }
      .detail-area {
        float: left;
        min-height: 169px;
        padding: 21px 30px;
        @include breakpoint(medium down) {
          padding-left: 3%;
          padding-right: 3%;
        }
        font-size: 17px;
        line-height: 1.3em;
        .btn {
          margin: 15px 0;
          display: block;
          width: 185px;
          a {
            padding-top: 5px;
            padding-bottom: 5px;
            font-weight: normal;
          }
        }
        .comment {
          font-size: 15px;
        }
        tr {
          &:first-child {
            th,
            td {
              padding-top: 0;
            }
          }
          th,
          td {
            padding-top: 16px;
          }
        }
        th,
        td {
          vertical-align: top;
        }
        th {
          @include breakpoint(medium down) {
            width: 3em;
          }
          &:after {
            content: "：";
          }
        }
      }
    }

    .p43-seminar-box {
      position: relative;
      background: #fff;
      margin-bottom: 30px;
      .link {
        background: #fff;
        a {
          display: block;
          padding: 17px 20px;
          &:after {
            @include cursor2(9px);
            border-top-width: 2px;
            border-right-width: 2px;
            margin-left: 5px;
            top: -1px;
            position: relative;
          }
        }
      }
    }

    .p43tp-contents-02 {
      margin-bottom: 60px;
      .page-contents-information {
        margin: 0;
        margin-top: 30px;
        &:first-child {
          margin-top: 0;
        }
      }
      .comment {
        padding-top: 5px;
      }
      .category {
        margin-left: 15px;
        &.program {
          background-color: #aec4e5;
          color: #333;
        }
      }
    }

    .p43tp-contents-03 {
      .title {
        padding: 17px;
        background: #aec4e5;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 0;
      }
      .page-contents-information {
        margin-top: 0;
        margin-bottom: 20px;
      }
    }

    .p43tp-contents-04 {
      margin-bottom: 57px;
    }

    .seminar-form-btn {
      @include breakpoint(large) {
        width: 415px;
        font-size: 20px;
      }
      @include breakpoint(medium down) {
        width: 90%;
      }
      margin: 0 auto;
      text-align: center;
      display: block;
      margin-bottom: 37px;
      a{
        padding-top: 15px;
        padding-bottom: 15px;
        color:#fff;
        font-family:$sans-serif;
      }
    }

    button.seminar-form-btn {
      @include breakpoint(large) {
        width: 415px;
        font-size: 20px;
      }
      @include breakpoint(medium down) {
        width: 90%;
      }
      margin: 0 auto;
      text-align: center;
      display: block;
      margin-bottom: 37px;
        padding-top: 20px;
        padding-bottom: 20px;
        color:#fff;
        font-family:$sans-serif;
        position: relative;
                &:after {
          @include cursor2(9px, #fff);
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 12px;
          border-top-width: 2px;
          border-right-width: 2px;
        }
    }


    .btn-area {
      .btn {
        @include breakpoint(large) {
          width: 262px;
        }
        a {
          @include breakpoint(large) {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }
    /* ==============================================
       p62
      =============================================== */
    .p62-contents-01 {
      margin-bottom: 30px;
    }
    .p62-contents-02 {
      padding: 30px 0;
      .contents-box-wrap {
        .box {
          padding-left: 20px;
          @include breakpoint(medium) {
            margin-top: 0;
            &:first-child {
              padding-left: 20px;
            }
          }
          @include breakpoint(small only) {
            padding-left: 0;
          }
        }
      }
    }

    .p62-contents-03 {
      padding: 30px 0;
      .contents-box-wrap {
        counter-reset: number 0;
        .box {
          @include breakpoint(xxlarge) {
            width: 265px;
          }
          @include breakpoint(medium) {
            margin-top: 0;
            padding-left: 15px;
          }
        }
        .box-content {
          background: #fff;
          padding: 20px;
          position: relative;
          min-height: 180px;
          &:before {
            @include number_font;
            counter-increment: number 1;
            content: counter(number) " ";
            position: absolute;
            font-size: 40px;
            color: #6088c6;
            left: 0;
            right: 0;
            margin: auto;
            line-height: 1.25em;
            font-weight: bold;
          }
        }
        .content {
          padding-top: 15px;
          margin-top: 58px;
          font-size: 15px;
          font-weight: bold;
          border-top: 1px solid #d0d0d0;
          text-align: left;
        }
      }
    }

    .p62-btn {
      @include breakpoint(medium) {
        width: 338px;
      }
      margin:0 auto;
      margin-top: 35px;
      text-align:center;
      display:block;
      a {
        padding-top: 17px;
        padding-bottom: 17px;
      }
    }
    /* ==============================================
       p80
      =============================================== */
    .privacy-title {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 9px;
    }

    .number-list {
      counter-reset: number 0;
      padding-left: 10px;
      li {
        padding-left: 2.5em;
        position: relative;
        &:before {
          counter-increment: number 1;
          content: "(" counter(number) ")";
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    .p80-contents-01 {
      padding-top: 45px;
    }
    .p80-contents-02,
    .p80-contents-03,
    .p80-contents-04,
    .p80-contents-05,
    .p80-contents-06,
    .p80-contents-07 {
      padding-top: 80px;
      .txt {
        margin-bottom: 20px;
      }
    }

    .privacy-box {
      background: #fff;
      padding: 30px;
      display: inline-block;
      margin-top: 80px;
      dl {}
      dt {
        margin-left: 15px;
        &:first-child {
          margin-left: 0;
        }
        &:after {
          content: ":";
          padding-left: 5px;
        }
      }
      dt,
      dd {
        display: inline-block;
      }
    }
    .privacy-box-address {
      margin-top: 30px;
    }

    /* ==============================================
       p14
      =============================================== */
    .p14-contents-01 {
      padding-top: 40px;
      margin-bottom: 40px;
    }

    .p14-link-list {
      text-align: center;
      @include breakpoint(xlarge down) {
        @include clearfix;
      }
      .p14-link-list-box-wrap {
        display: inline-block;
        @media screen and (min-width: 1200px) and (max-width: 1601px) {
          width:30.85%;
          &:first-child,
          &:nth-child(3n+1) {
            margin-left: 0;
          }
        }
        @include breakpoint(xxlarge) {
          margin-left: 25px;
        }
        @include breakpoint(xlarge down) {
          margin-top: 15px;
          margin-left: 3%;
          width: 48.5%;
          float: left;
          &:first-child,
          &:nth-child(2n+1) {
            margin-left: 0;
          }
          &:first-child,
          &:nth-child(2) {
            margin-top: 0;
          }
        }
        &:first-child {
          margin-left: 0;
        }
      }
      .p14-link-list-box {
        background: #fff;
        display: table;
        width: 100%;
        .photo {
          display: table-cell;
          width: 105px;
          height: 90px;
          @include breakpoint(small down){
            width:38%;
          }
          position: relative;
          vertical-align: middle;
          overflow: hidden;
          img {
            max-width: 105px;
            max-height: 90px;
            @include position-center;
          }
        }
        .name {
          padding: 20px;
          @include breakpoint(small down){
            padding:10px 2%;
          }
          display: table-cell;
          vertical-align: middle;
        }
      }
    }

    .p14-contents-main-box {
      background: #fff;
      margin-top: 25px;
      &:first-child {
        margin-top: 0;
      }
    }

    .p14-contents-main-box-top {
      position: relative;
      min-height: 200px;
      padding: 20px 20px 20px 18%;
      @include breakpoint(xlarge down) {
        padding-left: 30%;
      }
      @include breakpoint(medium only) {
        padding-left: 35%;
      }
      @include breakpoint(small only) {
        padding-top: 45vw;
        padding-left: 3%;
      }
      overflow:hidden;
      .photo {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        @include breakpoint(small only) {
          height: 48vw;
        }
        img {
          width: auto;
          max-width: none;
          height: 100%;
        }
      }
      .name,
      .voice-comment {
        position: relative;
        z-index: 10;
      }
      .name {
        top: 30px;
        font-size: 20px;
      }
      .voice-comment {
        top: 31px;
        font-size: 28px;
        font-weight: bold;
        padding-bottom: 35px;
        @include breakpoint(xlarge down) {
          font-size: 150%;
        }
      }
    }

    .p14-contents-main-box-status {
      background: #aec4e5;
      @include breakpoint(large) {
        padding: 10px 50px;
      }
      @include breakpoint(medium down) {
        padding: 10px 0 10px 3%;
      }
      dl {
        display: inline-block;
        @include breakpoint(large) {
          margin-left: 40px;
        }
        @include breakpoint(medium down) {
          margin-right: 3%;
        }
        &:first-child {
          margin-left: 0;
        }
        dt,
        dd {
          display: inline-block;
        }
        dt {
          &:after {
            content: "／";
          }
        }
      }
    }

    .p14-voice-list {
      @include breakpoint(large) {
        padding: 25px 0 35px 50px;
        width: 78%;
        float: left;
      }
      &.image-empty {
        width: 100%;
        padding-right: 50px;
        @media screen and (max-width: 1511px) {
          padding-right: 20px;
        }
      }
      @media screen and (max-width: 1511px) {
        padding-left: 20px;
      }
      @include breakpoint(large only) {
        padding-left: 3%;
      }
      @include breakpoint(medium down) {
        padding: 20px 3%;
      }
      dl {
        @include breakpoint(large) {
          padding-top: 30px;
          margin-top: 27px;
        }
        @include breakpoint(medium down) {
          padding-top: 20px;
          margin-top: 20px;
        }
        border-top: 1px solid #d0d0d0;
        &:first-child {
          padding-top: 0;
          margin-top: 0;
          border-top: 0;
        }
      }
      dt {
        margin-bottom: 20px;
        position: relative;
        padding-left: 2.75em;
        &:before {
          content: "";
          display: block;
          width: 24px;
          height: 21px;
          background: url(../img/page/ico_q.png) no-repeat;
          background-size: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      dd {
        position: relative;
        padding-left: 2.75em;
        &:before {
          content: "";
          display: block;
          width: 24px;
          height: 21px;
          background: url(../img/page/ico_a.png) no-repeat;
          background-size: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    .p14-content-main-list {
      @include clearfix;
    }

    .p14-photo-list {
      font-size: 14px;
      @include clearfix;
      @include breakpoint(large) {
        padding: 30px 50px 25px 0;
        width: 19.8%;
        float: right;
      }
      @media screen and (max-width: 1511px) {
        padding-right: 20px;
      }
      @include breakpoint(large only) {
        padding-right: 3%;
      }
      @include breakpoint(medium down) {
        padding: 20px 3% 30px 3%;
      }
      figure {
        margin-top: 15px;
        &:first-child{
          margin-top: 0;
        }
        @include breakpoint(medium down) {
          width: 79%;
          margin-left:auto;
          margin-right:auto;
        }
        img {
          display: block;
          @include breakpoint(small only) {
            width: 100%;
          }
          margin:0 auto;
        }
      }
      figcaption {
        padding-top: 5px;
        @include breakpoint(medium down) {
          padding-top: 10px;
        }
      }
    }
    /* ==============================================
       p90
      =============================================== */
    .l-form-flow {
      @include clearfix;
      position: relative;
      width: 835px;
      margin: 0 auto;
      margin-bottom: 30px;
      @media screen and(max-width: 836px) {
        width: 85%;
      }
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: #d0d0d0;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        @media screen and(max-width: 836px) {
          left: 45px;
          right: 0;
          width: 1px;
          height: 100%;
        }
      }
    }

    .form-flow-box {
      width: 225px;
      float: left;
      margin-left: 70px;
      @media screen and(max-width: 836px) {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
        &:first-child {
          margin-top: 0;
        }
      }

      z-index: 10;
      background: #fff;
      position: relative;
      &:first-child {
        margin-left: 0;
      }
      &:after {
        content: "";
        @include cursor(#fff, 23px, 22px, 20px);
        position: absolute;
        right: -20px;
        top: 0;
        @media screen and(max-width: 836px) {
          content: none;
        }
      }
      &.last {
        &:after {
          content: none;
        }
        width:245px;
        @media screen and(max-width: 836px) {
          width: 100%;
        }
      }
      &.current {
        background-color: #6088c6;
        .form-flow-number {
          background-color: #fff;
        }
        .form-flow-name {
          color: #fff;
          font-weight: bold;
        }
        &:after {
          border-color: transparent transparent transparent #6088c6;
        }
      }
    }

    .form-flow-number {
      background: #aec4e5;
      width: 45px;
      height: 45px;
      position: relative;
      float: left;
      span {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 1em;
        height: 1.25em;
        text-align: center;
      }
    }

    .form-flow-name {
      position: absolute;
      left: 45px;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      text-align: center;
      height: 1.45em;
    }

    .required {
      color: $strong_color;
      background-color: #fff;
      font-weight: bold;
      @include breakpoint(large down) {
        border-radius: 4px;
        padding: 2px 5px;
        margin-right: 5px;
      }
      @include breakpoint(xlarge) {
        border-radius: 4px;
        padding: 2px 5px;
        margin-right: 10px;
        font-size: 80%;
        top: -2px;
      }
      position: relative;
      top: -2px;
    }

    .form-area {
      .txt {
        padding: 40px 0 0 0;
      }
      table {
        margin-top: 20px;
        background: #fff;
        width: 100%;
        margin-bottom: 30px;
      }
      th,
      td {
        vertical-align: middle;
      }
      tr {
        &:first-child {
          th,
          td {
            border-top: 0;
          }
        }
      }
      th {
        font-weight: bold;
        position: relative;
        background-color: #aec4e5;
        padding: 20px 30px 20px 30px;
        border-top: 1px solid #fff;
        width: 280px;
        @media screen and (max-width: 814px) {
          width:auto;
          display: block;
          padding: 15px 3%;
        }
        .required {
          position: absolute;
          top: 0;
          right: 15px;
          height: 1.9em;
          bottom: 0;
          margin: auto;
        }
        &.vertical-align-top {
          .required {
            top: 20px;
            right: 15px;
            margin: 0;
            @media screen and (max-width: 814px) {
              top:0;
              bottom: 0;
              margin: auto;
            }
          }
        }
      }
      td {
        padding: 25px 30px;
        border-top: 1px solid #d0d0d0;
        @media screen and (max-width: 814px) {
          width:auto;
          display: block;
          padding: 15px 3%;
        }
        li {
          margin-top: 15px;
          .strong {
            padding-left: 20px;
          }
          &:first-child {
            margin-top: 0;
          }
        }
        .strong {
          display: block;
          padding-top: 5px;
        }
      }
      .vertical-align-top {
        vertical-align: top;
      }

      .seminar-name {
        display: block;
        padding-bottom: 17px;
      }
      .right-name {
        padding-left: 15px;
        @media screen and (max-width: 510px) {
          padding-left: 0;
        }
      }
      #name01,
      #name02,
      #hurigana01,
      #hurigana02 {
        width: 170px;
        margin-left: 5px;
        @media screen and (max-width: 510px) {
          width:100%;
          margin-left: 0;
        }
      }
      #age{
        width:60px;
      }
      #address01 {
        width: 60px;
      }
      #address02 {
        width: 80px;
      }
      #address03 {
        display: block;
        margin-top: 20px;
      }
      #address03,
      #tel01,
      #mail01,
      #mail02,
      #with_names,
      #place_names {
        width: 450px;
        @include breakpoint(small only) {
          width: 100%;
        }
      }
      .with-names-comment {
        padding-top: 15px;
      }
      #job,
      #annual-income,
      #case {
        width: 230px;
      }
      .form-inline {
        display: inline;
      }
      #date,
      #date_pub {
        width: 140px;
        margin-right: 20px !important;
      }
      #hour,
      #minute {
        width: 66px;
        margin-right: 10px;
        padding-top: 0;
        padding-bottom: 0;
      }

      #minute {
        margin-left: 20px;
      }
    }

    [type='text'],
    [type='password'],
    [type='date'],
    [type='datetime'],
    [type='datetime-local'],
    [type='month'],
    [type='week'],
    [type='email'],
    [type='number'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='url'],
    [type='color'],
    [type='file'],
    [type='checkbox'],
    [type='radio'],
    textarea,
    select {
      box-shadow: none;
      width: auto;
      display: inline;
      margin: 0;
    }

    textarea {
      width: 100%;
    }

    .privacy-area {
      width: 830px;
      margin: 0 auto;
      overflow-y: scroll;
      background: #fff;
      padding: 25px 30px;
      height: 155px;
      margin-bottom: 30px;
      font-size: 85%;
      @include breakpoint(xlarge only) {
        width: 80%;
      }

      @include breakpoint(medium down) {
        width: 100%;
      }
      .title {
        font-size: 20px;
        margin-bottom: 20px;
      }
      .sub-title {
        font-size: 16px;
        margin-bottom: 10px;
      }
      .privacy-title {
        font-size: 14px;
      }
      .p80-contents-01,
      .p80-contents-02,
      .p80-contents-03,
      .p80-contents-04,
      .p80-contents-05,
      .p80-contents-06,
      .p80-contents-07 {
        padding-top: 20px;
      }
      .privacy-box {
        margin-top: 20px;
        border: 1px solid #ccc;
      }
      .txt {
        padding: 0;
      }
    }

    .privacy-check {
      text-align: center;
      margin-bottom: 20px;
      label {
        cursor: pointer;
        display: inline-block;
        position: relative;
        &.is-on {
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 7px;
            display: block;
            margin-top: -8px;
            width: 13px;
            height: 24px;
            border-right: 3px solid #D65;
            border-bottom: 3px solid #D65;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            z-index: 1;
          }
        }
      }
      .privacy-checkbox {
        display: inline-block;
        padding: 13px;
        background-color: #fff;
        border-radius: 4px;
        top: 9px;
        position: relative;
        margin-right: 10px;
        border: 1px solid #d0d0d0;
        -moz-appearance: button;
        -webkit-appearance: button;
        appearance: button;
        outline: none;
        cursor: pointer;

        &:checked {
          box-shadow: 0px 0px 6px 3px #ebebeb;
          -moz-box-shadow: 0px 0px 6px 3px #ebebeb;
          -webkit-box-shadow: 0px 0px 6px 3px #ebebeb;
        }
      }
      label {
        display: inline-block;
      }
    }

    #err_privacy_check {
      .formErrorMsg {
        margin: 0 auto;
        margin-bottom: 25px;
      }
    }

    .complete-text {
      margin-bottom: 20px;
    }

    .error {
      font-size: 90%;
      margin-top: 10px;
      color: #fff;
      background-color: #e6a594;
      padding: 3px 15px;
      font-weight: bold;
      width: 230px;
      text-align: center;
      border-radius: 5px;
    }

    .form-send-btn {
      background: #4072b3;
      font-weight: bold;
      margin: 0 auto;
      position: relative;
      width: 342px;
      transition: .4s;
      &:hover {
        opacity: .7;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
      }
      &.btn-area {
        width: auto;
        background: none;
      }
      @include breakpoint(small only) {
        width: 90%;
      }

      #form-return-btn {
        background: #bbb;
      }
      #form-complete-btn {
        width: 200px;
      }
      &.navy {
        background-color: #1d2b77;
      }
      button {
        color: #fff;
        display: block;
        padding: 20px;
        cursor: pointer;
        font-family: $sans-serif;
        font-size: 20px;
        font-weight: bold;
        width: 100%;
        &:after {
          @include cursor2(9px, #fff);
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 12px;
          border-top-width: 2px;
          border-right-width: 2px;
        }
      }
    }

    /* ==============================================
       下層ページここまで
      =============================================== */
  }
}



.accordion-button {
  cursor: pointer;
}
